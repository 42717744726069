.wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    .body {
        display: flex;
        flex-direction: column;
        width: 1000px;
        background-color: #fff;
        padding: 0 40px;
        border-radius: 4px;
        background: #372e49cc;
        border: 2px solid #624266;
        .body-cart {
            .table {
                .title-table {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #7A7F9A;
                    font-weight: 600;
                    font-size: 14px;
                    .item-pack {
                        text-align: start;
                        color: white;
                        width: 50%;
                        .out-stock {
                            display: flex;
                            align-items: center;
                            color: red;
                        }
                    }
                    ;
                    .item-amount {
                        width: 20%;
                        color: white;
                        text-align: center;
                        .box-amount {
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            background-color: #302643 !important;
                            border-radius: 4px;
                            border: 1px solid #5E6484;
                            padding: 5px 10px;
                            font-size: 25px;
                            div {
                                cursor: pointer;
                            }
                            .number {
                                height: 90%;
                                border-radius: 4px;
                                background-color: #5c546a;
                                text-align: center;
                                padding: 10px;
                                font-size: 16px;
                                border: 1px solid #5E6484;
                                width: 40%;
                            }
                        }
                    }
                    ;
                    .item-price {
                        text-align: end;
                        margin-left: auto;
                        color: white;
                        div {
                            background: transparent;
                        }
                        .ant-select-selection-item {
                            color: white !important;
                        }
                    }
                    .title-row {
                        background: #4E4462;
                        display: flex;
                        padding: 12px;
                        margin-top: 10px;
                        >div {
                            color: white;
                        }
                    }
                    .col-btn {
                        display: flex;
                        justify-content: flex-end;
                        .btn-checkout {
                            padding: 15px;
                            color: #000;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                            border-radius: 4px;
                            background: linear-gradient(315.46deg, #D9A82A -0.13%, #EBDDB7 100%);
                            cursor: pointer;
                        }
                    }
                    .total {
                        padding: 25px 10px;
                        display: flex;
                        color: #17204D;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                        .item-price {
                            color: #E0C685;
                            font-size: 20px;
                            font-weight: 600;
                        }
                    }
                    .col-table {
                        display: flex;
                        align-items: center;
                        color: #17204D;
                        padding: 20px 10px;
                        border-bottom: 1px solid #ccc;
                        img {
                            width: 40px;
                            margin-right: 10px;
                        }
                        .item-rm {
                            display: none;
                        }
                        .item-price:hover {
                            .price {
                                display: none;
                            }
                            .item-rm {
                                display: flex;
                                justify-content: end;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                                color: red;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
        .header-cart {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            .title-header {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
            }
            .button-rm-all {
                cursor: pointer;
                background-color: rgba(9, 30, 66, 0.04);
                padding: 15px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

.popup-exchange {
    position: absolute;
    width: 100vw;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    /* margin-top: -54px; */
    justify-content: center;
    align-content: center;
    border-radius: 5px;
    z-index: 100000;
    display: flex;
    background: rgb(50 32 32 / 34%);
    .popup-sub-exchange {
        position: fixed;
        width: 500px;
        height: 500px;
        top: 50px;
    }
}

.number-highlighted {
    border-color: red !important;
}