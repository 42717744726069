$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
.cdc {
    position: absolute;
    z-index: 9;
    align-content: center;
    align-items: center;
    flex-direction: column;
    top: 97px;
    width: 100%;
    .text-welcome {
        font-size: 36px;
        color: #FFF;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

.count-down {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    max-width: 500px;
    align-items: center;
    border-radius: 20px;
    border: 2px solid;
    border-image-source: linear-gradient(165.1deg, #000000 -10.12%, rgba(255, 255, 255, 0.2) 119.7%);

    .text-welcome {
        position: absolute;
        top: -60px;
        font-size: 36px;
        font-weight: 600;
        color: #FFFFFF;
    }    
    .title {
        font-style: normal;
        font-weight: bold;
        text-align: center;
        color: #ffd362;
        @include mobile {
            font-size: 28px;
            line-height: 43px;
        }
        @include desktop() {
            font-size: 32px;
            line-height: 43px;
        }
    }
    .sub-title {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 1em;
        text-align: center;
        color: #ffffff;
        opacity: 0.6;
        padding-bottom: 20px;

    }
    .time-countdown {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .item-time {
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: #ffffff;
            .box-time {
                background-color: #E0C685;
                padding: 10px 15px;
                width: 59px;
                height: 51px;
                border-radius: 8px;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 31px;
                text-align: center;
                color: #242424;
                margin-bottom: 5px;
            }
        }
        .dot {
            color: #fff;
            font-size: 24px;
            padding-top: 6px;
        }
    }
    .btn-see-more {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        width: 125px;
        height: 40px;
        background: #d0b674;
        border-radius: 4px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #242424;
        cursor: pointer;
        img {
            width: 20px;
        }
    }
}

.count-down .text-welcome {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #FFFFFF;
}

.count-down .time-countdown {
    border-radius: 20px;
    position: relative;
    margin: 0 auto;
    padding: 20px 40px;
    background: rgba(55, 46, 73, 0.8);
    border-radius: 2px solid #624266;
}

.count-down .time-countdown>div {
    display: flex;
    flex-direction: row;
}

.count-down .time-countdown>div .box-time {
    display: inline-block;
    background: linear-gradient(316.04deg, #FADA87 29.12%, #FEEDAF 70.05%);
    padding: 10px 15px;
    width: 59px;
    height: 51px;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #242424;
    margin-bottom: 5px;
}

.count-down .time-countdown>div .item-time {
    color: #fff;
    flex: 1;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
}
.count-down .time-countdown>div .item-time h2{
    font-size: 12px;
    color: white;
    width: 100%;
}

.count-down .time-countdown>div .dot {
    color: #fce39b;
    font-size: 24px;
    font-weight: 600;
    padding:  6px 3px;
}

.count-down .time-countdown .sub-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 2em;
    text-align: center;
    color: #ffffff;
    opacity: 0.6;
}

.count-down .text-login-vip {
    padding: 5px 10px;
    background: white;
    display: inline-block;
    border-radius: 5px;
}

.count-down .tooltip-permision {
    font-size: 15px;
}

.count-down .tooltip-permision ul li {
    font-size: 15px;
}

.count-down .text-login-vip span {
    font-size: 20px;
    text-align: center;
}
