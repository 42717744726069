.wrap{
    background: url(../../../assets/images/polk/polk_background.jpg) 0 0 no-repeat;
    background-size: cover;
    padding-bottom: 20px;    
    .body{
        margin-top: 15px;
    }
    .warning-alert>div{
        width: 100%;
    }
}

.wrap-checkout {
    width: 600px;
    margin-bottom: 50px;
    border-radius: 4px;
    background: #372e49;
    border: 2px solid #624266;
    .ant-modal-content{
        background: transparent !important;
    }    
    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        background: #4E4462;
        padding: 15px 0;
        color: white;
    }
    .title-countdown {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 40px;
        flex-direction: column;
        align-items: center;
        .title {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: white;
        }
        .countdown {
            display: flex;
            font-size: 12px;
            line-height: 16px;
            color: #343C63;
            opacity: 0.6;
            .dot {
                margin: 0 10px;
                font-weight: 600;
                font-size: 24px;
            }
            .box {
                border: 1px solid rgba(179, 182, 197, 1);
                padding: 10px;
                border-radius: 8px;
                text-align: center;
                font-weight: 600;
                font-size: 20px;
                background: white;
            }
        }
    }
    .order-detail {
        padding: 0 40px 0px 40px;
        .order-detail-header {
            font-size: 14px;
            line-height: 1.5em;
            font-weight: 400;
            color: white;

        }
        th, td, tr{
            color: white;
        }
        tr{
            padding-top: 7px;
            padding-bottom: 7px;
        }
        table{
            border-radius: 10px;
        }
    }
    .desc {
        font-size: 14px;
        color: #091342;
        .note-box {
            padding: 24px;
            border-radius: 10px;
            background: #4E4462;
            ul {
                padding-left: 24px;
                li{
                    color: white;
                }
            }
            .primary-key {
                font-size: 16px;
                color: yellow;
            }
        }
        padding: 40px;
    }
    .input-id {
        padding: 0 40px 40px 40px;
        .tran_id {
            width: 100%;
            height: 40px;
            background: #40394F;
            border: 1px solid #C1C4D0;
            box-sizing: border-box;
            border-radius: 4px;
            color: white;
            padding: 0 5px;
        }
        .order-detail-header{
            font-size: 14px;
            line-height: 1.5em;
            font-weight: 400;
            color: white;
        }        
    }
    .btn-submit {
        padding: 0 40px 40px 40px;
        .col-btn {
            display: flex;
            justify-content: flex-end;
            .btn-checkout {
                padding: 10px 20px;
                text-transform: uppercase;
                color: #000;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                border-radius: 4px;
                background: linear-gradient(315.46deg, #D9A82A -0.13%, #EBDDB7 100%);
                cursor: pointer;
            }
        }
    }
}