@import '~react-perfect-scrollbar/dist/css/styles.css';
@import 'styles/antd';
@import 'styles/layout';
@import 'styles/components';
@import 'styles/bootstrap';
@import 'styles/responsive';

body {
  margin: 0;
  font-family: 'Segoe UI Variable', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./assets/images/polk/polk_background.jpg) 0 0 no-repeat;
  background-size: cover;
}

code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: 'Inconsolata', monospace !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

@font-face {
  font-family: 'Zen Antique Soft';
  src: url('https://fonts.googleapis.com/css2?family=Zen+Antique+Soft&display=swap') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display-Semibold.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('./assets/fonts/Segoe-UI-Variable-Static-Display.ttf') format('truetype');
  font-weight: 400;
}
// .MuiPaper-root{
//   background: transparent !important;
// }
.App-Header{
  width: 100%;
  padding: 0px 5px;
  height: 70px;
  background: #323232 !important;
}
.App-body{
  background: url(./assets/images/polk/polk_background.jpg) 0 0 no-repeat;
  background-size: cover;
}
.home{
  width: 100%;
  padding: 0px 5px;
  width: 100%;
  position: relative;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0px !important;
}
.containerBottom{
  background: #242424;
  padding-top: 30px;
  position: relative;
}
.containerBottom::after{
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 200px;
  height: 100px;
  background: url(./assets/images/polk/polk_logo_half.png) 0 100% no-repeat;
  background-size: contain;
}
.viplevel{
  font-size: 20px;
  h3{
    color: #6B718C;
  }  
  span{
    color: red;
  }
}
.footer-menu{
  display: block;
}
.footer-menu h2, .footer-contact h2{
  width: 100%;
  position: relative;
  float: left;
  font-size: 20px;
  line-height: 1.3em;
  color: #FFFFFF;
  font-weight: 600;
}
.footer-menu a{
  width: 100%;
  position: relative;
  float: left;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 2em;
}
.footer-contact a{
  position: relative;
  float: left;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 2em;
  padding-right: 10px;
}
.footer-contact a div{
  position: relative;
  float: left;
}
.footer-contact a img{
  max-height: 30px;
}

.row{
  padding: 0px;
  margin: 0px;
  margin-left: unset;
  margin-right: unset;
}

.bell-header{
  .anticon-bell{
    color: #FFF !important;
  }
  
}

@media (min-width: 1440px){
  .App-Header{
    background-color: #000 !important;
    padding: 5px calc(50% - 720px);
  }
}

