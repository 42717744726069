.CampaignSlide {
    width: 100vw;
    height: 46vw;
    background: url(../../assets/images/polk/polk_slide_bg.jpg) center center no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 0px 10%;
    .campaign-content{
        padding: 5% 0px;
    }
    .campaign-logo{
        height: 40px;
        width: 100%;
        max-width: 276px;
        padding-bottom: 30px;
        img{
            width: 100%;
        }

    }
    .campaign-welcome{
        width: 100%;
        h2{
            color: white;
            font-size: 40px;
            line-height: 1em;
            font-weight: bolder;
            padding-top: 50px;
        }
        h3{
            color: #f9cc57;
            font-size: 40px;
            line-height: 1em;
            font-weight: bolder;
            padding-bottom: 30px;
        }
    }
    .campaign-description{
        color: white;
        font-size: 16px;
        line-height: 1.5em;
        width: 100%;
        padding-right: 30%;
    }
    .campaign-countdown{
        padding: 50px 0px 0px 0px;
        .time-countdown{
            border: 2px solid #624266;
        }
    }

}
.btn-participate{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 125px;
    height: 40px;
    background: #d0b674;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #242424;
    cursor: pointer;
    img {
        width: 20px;
    }
}
.new-design {
    background: url(../../assets/images/polk/polk_button_participate.png) 0 0 no-repeat;
    background-size: contain;
    width: 200px;
    height: 50px;
    color: #7B4A00;
    font-weight: bolder;
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sale-end{
    background: #16131C !important;
    color: #FF5630  !important;
    border: 2px solid #FF5630  !important;
    font-size: 14px !important;
    box-sizing: border-box  !important;
    border-radius: 16px  !important;
}