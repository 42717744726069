$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

.home {
  margin-top: 48;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../../assets/images/polk/polk_background.jpg) 0 0 no-repeat;
  background-size: cover;
  .firstSlide{
    width: 100vw;
    height: 46vw;
    background: url(../../assets/images/polk/polk_slide_full.jpg) center center no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    .border-bottom{
      position: absolute;
      width: 100%;
      bottom: 0px;
      left: 0px;
      height: 8.889vw;
      background: url(../../assets/images/polk/polk_slide_bottom_border.png) center center no-repeat;  
      background-size: cover;
      z-index: 3;
    }
    .roadmap{
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0px;
      left: 0px;
      background: url(../../assets/images/polk/polk_roadmap.png) center center no-repeat;
      background-size: cover;
      opacity: 0.1;
      z-index: 2;
    }        
  }
  .firstSlide1::before{
    content: '';
    width: 100vw;
    height: 8.89vw;
    background: url(../../assets/images/polk/polk_slide_bottom_border.png) center center no-repeat;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 3;
    background-size: contain;
  }
  .firstSlide1::after{
    content: '';
    width: 100%;
    height: 46vw;
    background: url(../../assets/images/polk/polk_roadmap.png) center center no-repeat;
    background-size: contain;
    z-index: 2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    opacity: 0.1;
  }
  .home-product{
    padding: 70px calc(50% - 720px);
    position: relative;
    .layer-product-title{
      width: 60%;
      position: relative;
      margin: 0 auto;
      p{
        text-align: center;
      }
    }
    .title-layer{
      color: white;
      font-size: 36px;
      line-height: 1.5em;
      text-align: center;
      font-family: Zen Antique;
      height: 3em;
    }
    .title-layer::after{
      content: '';
      background: url(../../assets/images/polk/polk_button_diamond_line.png) center center no-repeat; 
      width: 100%;
      height: 44px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      background-size: contain;
    }
    .product-list{
      .product-item{
        width: 20%;
        cursor: pointer;
        z-index: 3;
        img{
          width: 100%;
        }
      }
    }
    .roadmap{
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0px;
      left: 0px;
      background: url(../../assets/images/polk/polk_roadmap.png) center center no-repeat;
      background-size: cover;
      opacity: 0.1;
      z-index: 2;
    }    
  }  
  .home-group-product{
    padding: 70px calc(50% - 720px);
    position: relative;
    .layer-product-title{
      width: 60%;
      position: relative;
      margin: 0 auto;
      p{
        text-align: center;
      }
    }
    .title-layer{
      color: white;
      font-size: 36px;
      line-height: 1.5em;
      text-align: center;
      font-family: Zen Antique;
      height: 3em;
    }
    .title-layer::after{
      content: '';
      background: url(../../assets/images/polk/polk_button_diamond_line.png) center center no-repeat; 
      width: 100%;
      height: 44px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      background-size: contain;
    }
    .product-list{
      .product-item{
        width: 33%;
        cursor: pointer;
        z-index: 3;
        img{
          width: 100%;
        }
      }
    }
    .roadmap{
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0px;
      left: 0px;
      background: url(../../assets/images/polk/polk_roadmap.png) center center no-repeat;
      background-size: cover;
      opacity: 0.1;
      z-index: 2;
    }    
  }  
  .home-inventory-product{
    position: relative;
    padding: 70px calc(50% - 720px);
    .layer-product-title{
      width: 60%;
      position: relative;
      margin: 0 auto;
    }
    .title-layer{
      color: white;
      font-size: 36px;
      line-height: 1.5em;
      text-align: center;
      font-family: Zen Antique;
      height: 3em;
    }
    .title-layer::after{
      content: '';
      background: url(../../assets/images/polk/polk_button_diamond_line.png) center center no-repeat; 
      width: 100%;
      height: 44px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      background-size: contain;
    }
    .product-list{
      z-index: 3;  
      .product-item{
        width: 33%;
        cursor: pointer;
        img{
          width: 100%;
        }
      }
    }
    .roadmap{
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0px;
      left: 0px;
      background: url(../../assets/images/polk/polk_roadmap.png) center center no-repeat;
      background-size: cover;
      opacity: 0.1;
      z-index: 2;
    }    
  }
  .ant-modal .modal-welcome {
    .level {
      color: red;
    }
  }
  .modal-welcome .ant-modal-body {
    border-radius: 20px 20px 0 0;
  }
  .modal-welcome h2{
    font-size: 29px;
    color: #17204D;
    font-weight: 600px;
    text-align: center;
  }
  .ant-modal-content {
    background-color: green !important;
  }
  h1 {
    color: #b88914;
  }
  h3 {
    color: #42496e;
    margin: 0;
  }
  .description {
    word-wrap: break-word;
    color: #5e6484;
    font-size: 16px;
    margin: 20px 0px 42px 0px;
    text-align: center;
  }
  .button {
    width: 360px;
    height: 60px;
    background: linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%); 
    box-shadow: 0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 4px;
    cursor: pointer;
    color: #4d3700;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .list-image{
    padding: 0px calc(50% - 730px);
  }
  img {

    @include mobile {
      width: 100%;
      height: 100%;
    }
    @include desktop() {
      object-fit: cover;
    }
  }
}

.rule-user {
  display: flex;
  background-color: rgb(244, 244, 247);
  flex-direction: column;
  align-items: center;
  .item {
    width: 134px;
  }
}
.App-Body {
    padding: 0px !important;
    overflow: hidden;
    background: url(../../assets/images/polk/polk_background.jpg) 0 0 no-repeat;
    background-size: cover;
    padding-bottom: 20px !important;

    .home h1{
      color: #E0C685;
      margin-top: 30px;
    }
    .home h3{
      color: #fff;
      text-transform: uppercase;
      font-size: 32px;
    }
    .home .button{
      color: #17204D;
      text-transform: uppercase;
      margin-bottom: 50px;
      font-size: 16px;
    }
    .home .list-image img{
      width: 100%;
    }
}
@media (max-width: 1440px){
    .home .list-image{
      padding: 0px 10px;
    }
}