// Global
.order-history{
    width: 60%;
    margin: 0 auto;
    padding: 20px 30px;
    border: 2px solid #624266;
    border-radius: 16px;
    h2{
        color: white;
        padding-left: 20px;
        font-size: 32px;
        color: #E0C685;
        padding-left: 0px;
    }
    .ant-card-bordered{
        border: unset;
    }
    .ant-table{
        background: transparent;
        color: white;
    }
    .ant-table-thead > tr > th{
        background: transparent;
        color: white;
    }
    div, th, td, tr, thead{
        background-color: transparent !important;
        border: unset;
    }
    tr{
        border-bottom: none; 
    }
    thead tr th{
        font-size: 16px;
    }
}
.H3 {
    font-size: 24px;
}

.history--container {
    width: 1077px;
    // height: 750px;
    padding: 32px;
    background-color: #FFFFFF;
    transition: height 2s;
}

.history--title {
    display: block;
    font-weight: 600;
    margin-bottom: 20px;
    @extend .H3;
}

// Table
// // Table Header
.history--table--header {
    font-weight: 600;
    font-size: 14px;
    color: #7A7F9A;
    background-color: #EBECF0;
    padding: 12px;
    box-shadow: inset 0px -1px 0px rgba(193, 196, 208, 0.5);
}

// // Table Body
.history--table--item--container {
    padding: 8px 12px;
    width: 100%;
    height: 72px;
    margin: 0;
    opacity: 1;
}

.history--table--itemimage {
    width: 39.2px;
    height: 56px;
    margin-right: 16px;
}

.history--table--text {
    font-size: 14px;
    font-style: normal;
    color: #17204D;
    font-weight: normal;
}

.history--table--date {
    @extend .history--table--text;
    color: #7A7F9A;
}

// // // Table Body Price
.history--table--price {
    @extend .history--table--text;
    color: #B88914;
}

// // // Table Body Status
$status__processing: #FF991F;
$status__successful: #00875A;
$status__failed: #DE350B;
.history--processing {
    background-color: $status__processing;
}

.history--successful {
    background-color: $status__successful;
}

.history--failed {
    background-color: $status__failed;
}

.history--status {
    width: 85px;
    height: 20px;
    border-radius: 3px;
    padding: 2px 6px 2px 6px;
    text-align: center;
    box-sizing: content-box;
    @extend .history--processing;
    & .history--status--text {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
    }
}

.hidden {
    opacity: 0;
    transition: opacity 0.3s;
}