html,
body {
    height: 100%;
    background-color: #f1f1f3;
}

.item-card-popup .ant-modal-content {
    background: transparent !important;
}

.add-to-cart-btn * {
    margin: 10px;
}

.btn-c {
    background: linear-gradient( 315.46deg, #D9A82A -0.13%, #EBDDB7 100%) !important;
}

.add-to-cart-btn,
.buy-now-btn {
    background: transparent;
    padding: 5px 40px;
    color: white;
    height: 48px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    min-width: 240px;
    text-align: center;
}

.add-to-cart-btn:hover {
    background: black;
}

.add-to-cart-btn,
.buy-now-btn span {
    text-align: center;
    flex: 1;
    text-transform: uppercase;
}

.buy-now-btn span {
    color: black;
}

.warning-alert>div {
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    background: yellow;
    color: black;
    background: #d6b81b;
}

.header-level {
    background-Color: red;
    color: white;
    padding: 0px 15px;
    font-weight: bold;
    font-size: 14px;
}

.header-level:hover {
    background-color: #cfb675;
}

.header-address {
    margin: 0 5px;
}