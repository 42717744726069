.modal-cu {
    position: fixed;
    height: 100vh;
    top: 0;
    z-index: 1000;
    left: 0;
    width: 100vw;
    background-size: 90%;
    background-repeat: no-repeat;
    padding: 50px 15%;
    background-position: center center;
    background-color: #04050ecc;
    >div {
        padding: 20px 40px;
        border-radius: 16px;
        background: rgba(55, 46, 73, 0.8);
    }
}

.launchpad-container {
    width: 100%;
}

.border-bottom {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    height: 8.889vw;
    background: url(../../assets/images/polk/polk_slide_bottom_border.png) center center no-repeat;
    background-size: cover;
    z-index: 3;
}

.box-header {
    padding-left: 60px;
}

.box-header::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
    background: url(../../assets/images/polk/polk_boxheader_arrow.png) 0 0 no-repeat;
    background-size: contain;
}

.box-header::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 0px;
    width: 138px;
    height: 30px;
    background: url(../../assets/images/polk/polk_boxheader_star.png) 0 0 no-repeat;
    background-size: contain;
}

.launchpad-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../../assets/images/polk/polk_background.jpg) 0 0 no-repeat;
    background-size: cover;
    margin: 0px;
    .product-group {
        width: 100vw;
        padding: 50px calc(50% - 720px);
        position: relative;
        h2 {
            color: #EAB553;
            font-size: 24px;
            line-height: 32px;
            height: 32px;
            padding-left: 30px;
            font-weight: bold;
            font-style: italic;
            border-bottom: 1px solid #c6b7a033;
        }
        .product-list {
            padding: 0px;
            margin-top: 30px;
            .list-item {
                padding: 32px 0px;
            }
        }
    }
    .live-auction {
        width: 100vw;
        padding: 50px calc(50% - 720px);
        position: relative;
        .live-auction-content {
            padding: 20px 10%;
        }
        h2 {
            color: #EAB553;
            font-size: 24px;
            line-height: 32px;
            height: 32px;
            padding-left: 30px;
            font-weight: bold;
            font-style: italic;
        }
        .border-bottom {
            position: absolute;
            width: 100%;
            bottom: 0px;
            left: 0px;
            height: 8.889vw;
            background: url(../../assets/images/polk/polk_slide_bottom_border.png) center center no-repeat;
            background-size: cover;
            z-index: 3;
        }
    }
}

.card-item {
    background: rgba(55, 46, 73, 0.8) !important;
    .ant-col {
        padding: 0 !important;
        .level-top-item {
            height: 50px;
            font-size: 18px;
            text-align: center;
            color: #FFF;
            line-height: 50px;
            background: rgba(55, 46, 73, 0.8);
            border: 2px solid #624266;
            border-bottom: unset;
            text-transform: uppercase;
            font-weight: 600;
            font-family: 'Zen Antique';
        }
    }
    .item-infor-bottom {
        height: 50px;
        line-height: 50px;
        color: #FFF;
        padding: 0 10px;
        justify-content: space-between;
        // background: rgba(55, 46, 73, 0.8);
        border: 2px solid #624266;
        border-bottom: unset;
        position: relative;
        overflow: hidden;
        border-top: none;
        // background: url(../../assets/images/polk/polk_button_product_1.png) 0 0 no-repeat !important;
        .polk-button {
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0px;
            left: 0px;
            img {
                width: 100%;
            }
        }
        .price {
            color: #FFE6A5;
            font-weight: 600;
            font-size: 18px;
            z-index: 2;
            padding-left: 5px;
        }
        .inventory {
            color: white;
            font-weight: 300;
            font-size: 14px;
            z-index: 2;
            padding-right: 5px;
        }
        .soldout-text {
            width: 100%;
            display: flex;
            text-align: center;
            h3 {
                text-align: center;
                color: white;
                width: 100%;
                flex: 1;
                align-items: center;
                padding: 0px;
                margin: 0px;
                line-height: 50px;
                font-size: 24px;
            }
        }
    }
    .item-infor-bottom_1 {
        height: 80px;
        line-height: 50px;
        color: #FFF;
        padding: 0 10px;
        justify-content: space-between;
        border: 2px solid #624266;
        border-top: unset;
        position: relative;
        overflow: hidden;
        border-top: none;
        cursor: pointer;
        border-left: 12px solid red;
        .polk-button {
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0px;
            left: 0px;
            border-left: 12px solide red;
            img {
                width: 100%;
            }
        }
        .polk-button::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 40%;
            left: 0px;
            bottom: 0px;
            background: #372e49;
        }
        .price {
            color: #FFE6A5;
            font-weight: 700;
            font-size: 22px;
            line-height: 45px;
            z-index: 2;
            text-align: center;
            flex: 1;
        }
        .inventory {
            color: #FF8484;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            border-radius: 10px;
            flex: 1;
            z-index: 2;
            text-align: center;
            span {
                padding: 3px 10px;
                border: 1px solid #FF8484;
                border-radius: 26px;
            }
        }
        .soldout-text {
            width: 100%;
            display: flex;
            text-align: center;
            h3 {
                text-align: center;
                color: white;
                width: 100%;
                flex: 1;
                align-items: center;
                padding: 0px;
                margin: 0px;
                line-height: 50px;
                font-size: 24px;
            }
        }
    }
    .ant-card-body {
        padding: 0;
    }
    .card-item {
        padding: 0;
    }
}

.launchpad-container {
    position: relative;
    .count-down {
        border-radius: 2px;
    }
    color: #FFF;
    .ant-row {
        background: url(../../assets/images/polk/polk_roadmap.png) center center no-repeat !important;
    }
    .ant-card {
        background: url(../../assets/images/polk/polk_roadmap.png) center center no-repeat !important;
    }
    .box-image {}
    .ant-card.ant-card-bordered.ant-card-large {
        border: none;
    }
}

.product-group .product-list .card-item {
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31);
    border-radius: 8px;
}

.product-group .product-list h1 {
    font-size: 33px;
}

.product-group .product-list .card-item h2 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 28px;
}

.product-group .product-list .time-left {
    color: #5e6484;
    font-weight: 400;
    font-size: 14px;
}

.product-group .product-list .infor .title {
    font-weight: bold;
    font-size: 16px;
}

.product-group .product-list .infor .description {
    color: #7a7f9a;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
}

.product-group .product-list .box-price {
    display: flex;
    align-items: flex-end;
}

.product-group .product-list .box-price .price {
    color: #B88914;
    font-size: 20px;
    font-weight: 600;
    margin-left: 5px;
}

.product-group .product-list .box-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 25px;
    border: 2px solid #624266;
    border-bottom: unset;
}

.product-group .product-list .box-image .item {
    width: 216px;
}

.item-infor-bottom {}
