.count-down-product{
    border-radius: 16px;
    background: rgba(55, 46, 73, 0.8);
    box-shadow: 0px 0px 200px rgba(112, 0, 255, 0.35);
    border: 2px solid #624266;
    position: relative;
    padding: 20px !important;
    div{
        margin: 0px;
        padding: 0px;
    }
    .auction-info{
        padding: 0px 0px 0px 20px;
        > div{
            flex: 1;
        }
        .title{
            font-size: 32px;
            color: white;
            line-height: 1.5em;
        }
        .btn-see-more{
            background: linear-gradient(31.65deg, #DEB856 6.17%, #FDE29B 93.12%);
            border-radius: 4px;
            width: 100%;
            height: 56px;
            box-shadow: 0px 1px 30px rgba(9, 30, 66, 0.05), 0px 0px 1px rgba(9, 30, 66, 0.31);
            text-align: center;
            font-size: 16px;
            line-height: 56px;
            color: #17204D;
            text-transform: uppercase;
            font-weight: bold;
        }
        .countdowntime{
            background: #382957;
            border: 1px solid #DA8AFF;
            box-shadow: 0px 8px 32px rgba(66, 0, 255, 0.12), 0px 3px 6px rgba(66, 0, 255, 0.11);  
            border-radius: 8px;
            padding: 10px;
            margin-top: 20px;
            h3{
                color: white;
                font-size: 16px;
                width: 100%;
                text-align: center;
                line-height: 2em;
            }
            h4{
                color: white;
                font-size: 24px;
                line-height: 2em;
                text-align: center;
                width: 100%;
                font-weight: bolder;
            }          
        }    
        .currentbid{
            background: #382957;
            border: 1px solid #DA8AFF;
            box-shadow: 0px 8px 32px rgba(66, 0, 255, 0.12), 0px 3px 6px rgba(66, 0, 255, 0.11);  
            border-radius: 8px;
            padding: 10px;
            margin-top: 20px;
            h3{
                color: white;
                font-size: 16px;
                width: 100%;
                text-align: center;
                line-height: 2em;
            }  
            div{
                text-align: center;
                width: 100%;
                img{
                    height: 24px;
                    width: 24px;
                    margin-right: 10px;
                    display: inline-block;
                }
                span{
                    font-size: 24px;
                    line-height: 24px;
                    display: inline-block;
                    color: #FFE6A5;
                }
                margin-bottom: 10px;
            }
        }
    }
}
.auction-image{
    img{
        width: 100%;    
    }
    
}